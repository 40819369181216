@use '../../scss/' as *;
.tf-team {
    background: #2E2D2B ;
}

.home-2 {
    .tf-team {
        padding: 91px 0 51px;
    }
}

.about {
    .tf-team {
        padding: 91px 0 0;
        background: transparent;
    }
}